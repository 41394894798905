<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchForm.accountNumber"
            placeholder="用户邮箱"
            clearable
        >
        </el-input>



      </el-form-item>

      <el-form-item>
<!--        <el-select v-model="searchForm.type"    placeholder="请选择交易类型" >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"

              >
          </el-option>
        </el-select>
        &nbsp;
        <el-select v-model="searchForm.inIcon"  width="10px"   placeholder="请选择充值提现类型" >
          <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"

          >
          </el-option>
        </el-select>-->

        &nbsp;
        <el-select v-model="searchForm.state"  width="10px"   placeholder="请选择交易状态" >
          <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"

          >
          </el-option>
        </el-select>
      </el-form-item>




      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>
<!--
      <el-form-item>
        <el-popconfirm title="批量确认放款吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('sys:user:delete')">批量确认</el-button>
        </el-popconfirm>
      </el-form-item>
-->

<!--      <el-form-item>
          <el-button type="primary" @click="dialogVisible = true" v-if="hasAuth('shares:ceiling:save')">更新</el-button>
      </el-form-item>-->
<!--      <el-form-item>
        <el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('sys:user:delete')">批量删除</el-button>
        </el-popconfirm>
      </el-form-item>-->
    </el-form>

    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @cell-dblclick="rowClick"
        border
        stripe
        @selection-change="handleSelectionChange">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>


      <el-table-column
          prop="id"
          label="id"
          width="70">
      </el-table-column>


      <!---->
      <el-table-column
          prop="spare1"
          label="用户邮箱"
          width="180">
      </el-table-column>


      <el-table-column
          prop="amount"
          label="交易金额 "
          width="80">
      </el-table-column>

      <el-table-column
          prop="afterAmount"
          label="交易后金额 "
          width="100">
      </el-table-column>

      <!---->
      <el-table-column
          prop="details"
          label="交易详情"
          width="300">
      </el-table-column>
      <el-table-column
          prop="type"
          label="交易类型"
          width="116">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.type === '1'" type="success">Usdt充提记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '2'" type="success">Usd兑换记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '3'" type="success">卡片充提记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '4'" type="success">佣金记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '5'" type="success">CNY充提记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '6'" type="success">内部转账</el-tag>

        </template>
      </el-table-column>

      <el-table-column
          prop="inIcon"
          label="充值提现"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.inIcon === 'In'" type="primary">充值</el-tag>
          <el-tag size="small" v-else-if="scope.row.inIcon === '0ut'" type="warning">提现</el-tag>


        </template>
      </el-table-column>

      <el-table-column
          prop="state"
          label="交易状态"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.state === 'CONFIRMED'" type="success">已确认</el-tag>
          <el-tag size="small" v-else-if="scope.row.state === 'UNCONFIRMED'" type="danger">未确认</el-tag>
          <el-tag size="small" v-else-if="scope.row.state === 'CONFIRMING'" type="danger">交易中</el-tag>

        </template>
      </el-table-column>

      <el-table-column
          prop="creationTime"
          label="交易时间"
          width="165">
      </el-table-column>

      <!---->

      <el-table-column
          prop="hash"
          label="交易hash"
          width="180">
      </el-table-column>

      <el-table-column
          prop="address"
          label="转出地址 "
          width="180">
      </el-table-column>

      <el-table-column
          prop="cashAddress"
          label="接收地址 "
          width="180">
      </el-table-column>



      <el-table-column
          prop="icon"
          width="80px"
          label="操作">

        <template slot-scope="scope">




          <template v-if="scope.row.hash==null">
            <el-button type="text" @click="editHandle(scope.row.id)">审核</el-button>
          </template>

        </template>

      </el-table-column>

      <!--      <el-table-column
                prop="industry"
                label="所属行业"
                width="180">
            </el-table-column>-->




    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[15, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>


    <!--新增对话框-->
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="handleClose">

      <el-form :model="editForm" :rules="editFormRules" ref="editForm">

        <el-form-item label="详情"  prop="details" label-width="100px">
          <el-input v-model="editForm.details" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="谷歌验证"  prop="spare2" label-width="100px">
          <el-input v-model="editForm.spare2" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
      </div>
    </el-dialog>




    <!-- 分配权限对话框 -->
    <el-dialog title="分配角色" :visible.sync="roleDialogFormVisible" width="600px">

      <el-form :model="roleForm">
        <el-tree
            :data="roleTreeData"
            show-checkbox
            ref="roleTree"
            :check-strictly=checkStrictly
            node-key="id"
            :default-expand-all=true
            :props="defaultProps">
        </el-tree>


      </el-form>

      <div slot="footer" class="dialog-footer">



        <el-button @click="roleDialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Role",
  data() {
    return {
      options: [ {
        value: '',
        label: ''
      }, {
        value: '1',
        label: 'Usdt充提记录'
      }, {
        value: '2',
        label: 'Usd兑换记录'
      }, {
        value: '3',
        label: '卡片充提记录'

      },{
        value: '4',
        label: '佣金记录'
      },{
        value: '5',
        label: 'CNY充提记录'
      },{
        value: '6',
        label: '内部转账'
      }]
      ,
      options2: [ {
        value: '',
        label: ''
      }, {
        value: 'In',
        label: '充值'
      }, {
        value: '0ut',
        label: '提现'
      }],
      options3: [ {
        value: '',
        label: ''
      }, {
        value: 'CONFIRMED',
        label: '已确认'
      }, {
        value: 'UNCONFIRMED',
        label: '未确认'
      }, {
        value: 'CONFIRMING',
        label: '交易中'
      }],

      searchForm: {},
      delBtlStatu: true,

      total: 0,
      size: 15,
      current: 1,

      dialogVisible: false,
      editForm: {

      },

      tableData: [],

      editFormRules: {
        spare2: [
          {required: true, message: '验证码不可为空', trigger: 'blur'}
        ]
      },

      multipleSelection: [],

      roleDialogFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleForm: {},
      roleTreeData:  [],
      treeCheckedKeys: [],
      checkStrictly: true

    }
  },
  created() {



    this.getUserList()

    this.$axios.get("/sys/roleMGQZHP/list").then(res => {
      this.roleTreeData = res.data.data.records
    })


  },
  methods: {

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
  rowClick(row,column,event) {
    this.editHandle(row.id)
    console.log(row);///获取该行的所有数据
    console.log(column);
    console.log(event);
  },
    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getUserList()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    handleClose() {
      this.resetForm('editForm')
    },

    getUserList() {
      this.$axios.get("/card/recordAudit/list", {
        params: {
          username: this.searchForm.accountNumber,
          type: this.searchForm.type,
          inIcon:this.searchForm.inIcon,
          state:this.searchForm.state,
          current: this.current,
          size: this.size
        }
      }).then(res => {
        this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert((this.editForm.id?'update' : 'save'))
          this.$axios.post('/card/recordAudit/' + (this.editForm.id?'update' : 'save'), this.editForm)
              .then(res => {

                this.$message({
                  showClose: true,
                  message: '恭喜你，操作成功',
                  type: 'success',
                  onClose:() => {
                    this.getUserList()
                  }
                });

                this.dialogVisible = false
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get('/card/recordAudit/info/' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    delHandle(id,state,type) {

      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
     // alert(state);

      if(type == '3'){
        if(state == 'UNCONFIRMED'){
          this.$axios.post("/card/recordAudit/update", ids).then(res => {
            this.$message({
              showClose: true,
              message: '恭喜你，操作成功',
              type: 'success',
              onClose:() => {
                this.getUserList()
              }
            });
          })
        }else{
          this.$message({
            showClose: true,
            message: '数据为已确认状态，不可再次确认。',
            type: 'danger',
            onClose:() => {
              this.getUserList()
            }
          });
        }
      }else{
        this.$message({
          showClose: true,
          message: '只有充值银行卡类型才能操作。',
          type: 'danger',
          onClose:() => {
            this.getUserList()
          }
        });
      }

    },

    roleHandle (id) {
      this.roleDialogFormVisible = true

      this.$axios.get('/sys/userWQKKDUXHAL/info/' + id).then(res => {
        this.roleForm = res.data.data

        let roleIds = []
        res.data.data.sysRoles.forEach(row => {
          roleIds.push(row.id)
        })

        this.$refs.roleTree.setCheckedKeys(roleIds)
      })
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys()

      console.log(roleIds)

      this.$axios.post('/sys/userWQKKDUXHAL/role/' + this.roleForm.id, roleIds).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });

        this.roleDialogFormVisible = false
      })
    },
    repassHandle(id, username) {

      this.$confirm('将重置用户【' + username + '】的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/userWQKKDUXHAL/user/repass", id).then(res => {
          this.$message({
            showClose: true,
            message: '恭喜你，操作成功',
            type: 'success',
            onClose: () => {
            }
          });
        })
      })
    }
  }
}
</script>

<style scoped>

.el-pagination {
  float: right;
  margin-top: 22px;
}

</style>